import { graphql } from "gatsby"
import { PrivacyPolicy } from "../PrivacyPolicy/PrivacyPolicy"

export default PrivacyPolicy

export const query = graphql`
  query PrivacyPolicyPage {
    site {
      ...SiteFragment
    }
    header: markdownRemark(fileAbsolutePath: { regex: "/netlify/header.md/" }) {
      ...HeaderFragment
    }
    footer: markdownRemark(fileAbsolutePath: { regex: "/netlify/footer.md/" }) {
      ...FooterFragment
    }
  }
`
